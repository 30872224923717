var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"position-relative"},[_c('b-button',{staticClass:"px-6 add-new-btn header-btn btn-primary-light",on:{"click":function($event){return _vm.openNewApplicationModal()}}},[_c('b-icon',{attrs:{"icon":"plus","size":"is-small"}}),_vm._v("New App ")],1)],1),(!_vm.isAppFetchDone)?[_c('div',{staticClass:"columns is-vcentered px-5"},[_c('div',{staticClass:"is-10 column"},[_c('b-skeleton',{attrs:{"height":"30"}}),_c('b-skeleton',{attrs:{"height":"50"}})],1),_c('div',{staticClass:"is-2 column"},[_c('b-skeleton',{attrs:{"height":"30"}})],1)])]:(_vm.applications.length === 0)?[_c('NoResults',{attrs:{"banner-label":"No applications yet, create one now.","button-label":"New App","button-handler":_vm.openNewApplicationModal}})]:[_c('b-table',{staticClass:"tab-table-layout",attrs:{"data":_vm.applications},on:{"click":_vm.goToModules}},[_c('b-table-column',{attrs:{"label":"Name","field":"name","width":"40%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Author","field":"author"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-tooltip',{attrs:{"label":row.author}},[_c('b-button',{staticClass:"button disk disk-medium is-primary"},[_vm._v(" "+_vm._s(row.initials)+" ")])],1)]}}])}),_c('b-table-column',{attrs:{"label":"Last modified at","field":"updated_at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.updated_at)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Created at","field":"created_at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.created_at)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"modules","field":"modules"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.modules)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"","cell-class":"has-text-right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-button',{staticClass:"app-dropdown-btn",attrs:{"size":"is-small"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleActionDropdown(row.id)}}},[_c('b-icon',{staticClass:"app-dropdown-icon",attrs:{"icon":"dots-horizontal","size":"is-size-6"}})],1),_c('b-dropdown',{ref:("appActionDropdown" + (row.id)),staticClass:"app-action-dropdown",attrs:{"aria-role":"list","position":"is-bottom-left","append-to-body":""}},[_c('b-dropdown-item',{staticClass:"is-flex is-align-items-center",attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.goToAppSettings(row.id)}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"pencil-outline","type":"is-black","custom-size":"mdi-22px"}}),_vm._v(" Edit ")],1),_c('b-dropdown-item',{staticClass:"is-flex is-align-items-center",attrs:{"aria-role":"listitem","disabled":""}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"content-copy","type":"is-black","custom-size":"mdi-22px"}}),_vm._v(" Duplicate ")],1),_c('b-dropdown-item',{staticClass:"is-flex is-align-items-center is-justify-content-center",attrs:{"aria-role":"listitem","custom":""}},[_c('b-button',{staticClass:"rounded-8 w-full",attrs:{"icon-left":"delete-outline","size":"is-small","type":"is-danger is-light"},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDelete(row)}}},[_vm._v(" Delete ")])],1)],1)]}}])})],1)],_c('ConfirmDeleteModal',{attrs:{"entity-name":_vm.appToBeDeleted.name,"has-modal-card":true,"trap-focus":true,"destroy-on-hide":false,"aria-role":"dialog","aria-label":"Confirm Delete Application","aria-modal":""},on:{"delete":function($event){return _vm.deleteApp()}},model:{value:(_vm.isDeleteAppModalVisible),callback:function ($$v) {_vm.isDeleteAppModalVisible=$$v},expression:"isDeleteAppModalVisible"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }