<template>
  <div>
    <div class="position-relative">
      <b-button
        class="px-6 add-new-btn header-btn btn-primary-light"
        @click="openNewApplicationModal()"
      >
        <b-icon
          icon="plus"
          size="is-small"
        />New App
      </b-button>
    </div>

    <template v-if="!isAppFetchDone">
      <div
        class="columns is-vcentered px-5"
      >
        <div class="is-10 column">
          <b-skeleton height="30" />
          <b-skeleton height="50" />
        </div>
        <div class="is-2 column">
          <b-skeleton height="30" />
        </div>
      </div>
    </template>
    <template v-else-if="applications.length === 0">
      <NoResults 
        banner-label="No applications yet, create one now."
        button-label="New App"
        :button-handler="openNewApplicationModal"
      />
    </template>
    <template v-else>
      <b-table
        :data="applications"
        class="tab-table-layout"
        @click="goToModules"
      >
        <b-table-column
          v-slot="{ row }"
          label="Name"
          field="name"
          width="40%"
        >
          {{ row.name }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Author"
          field="author"
        >
          <b-tooltip :label="row.author">
            <b-button class="button disk disk-medium is-primary">
              {{ row.initials }}
            </b-button>
          </b-tooltip>
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Last modified at"
          field="updated_at"
        >
          {{ row.updated_at }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Created at"
          field="created_at"
        >
          {{ row.created_at }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="modules"
          field="modules"
        >
          {{ row.modules }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label=""
          cell-class="has-text-right"
        >
          <b-button
            size="is-small"
            class="app-dropdown-btn"
            @click.stop="toggleActionDropdown(row.id)"
          >
            <b-icon
              icon="dots-horizontal"
              size="is-size-6"
              class="app-dropdown-icon"
            />
          </b-button>
          <b-dropdown
            :ref="`appActionDropdown${row.id}`"
            aria-role="list"
            position="is-bottom-left"
            class="app-action-dropdown"
            append-to-body
          >
            <b-dropdown-item
              aria-role="listitem"
              class="is-flex is-align-items-center"
              @click="goToAppSettings(row.id)"
            >
              <b-icon
                icon="pencil-outline"
                type="is-black"
                custom-size="mdi-22px"
                class="mr-2"
              />
              Edit
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              class="is-flex is-align-items-center"
              disabled
            >
              <b-icon
                icon="content-copy"
                type="is-black"
                custom-size="mdi-22px"
                class="mr-2"
              />
              Duplicate
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              custom
              class="is-flex is-align-items-center is-justify-content-center"
            >
              <b-button
                icon-left="delete-outline"
                size="is-small"
                type="is-danger is-light"
                class="rounded-8 w-full"
                @click.stop="confirmDelete(row)"
              >
                Delete
              </b-button>
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
      </b-table>
    </template>
    <ConfirmDeleteModal
      v-model="isDeleteAppModalVisible"
      :entity-name="appToBeDeleted.name"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Confirm Delete Application"
      aria-modal
      @delete="deleteApp()"
    />
  </div>
</template>

<script >
import { defineAsyncComponent, getCurrentInstance, onMounted, reactive, ref } from '@vue/composition-api';
import { deleteApplicationService, fetchApplicationsService } from '@/services/application-service/applicationRequests';
import { useCoreApplicationStore } from '@/modules/core/store/coreApplicationStore';
import { useRouter } from '@/hooks/vueRouter';
import { useBuefy } from '@/hooks/buefy';
import { convertUTCToLocalTime } from '@/helpers/util';

//-- child components --//
const ConfirmDeleteModal = defineAsyncComponent(() => import('@/modules/core/components/ConfirmDeleteModal.vue'));
const NoResults = defineAsyncComponent(() => import('./NoResults.vue'));

// @VUE3: do not use this function when migrating to vue 3
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const vm = getCurrentInstance();

  //-- composable hooks --//
  const coreApplicationStore = useCoreApplicationStore();
  const router = useRouter();
  const buefy = useBuefy();

  //-- new application logic --//
  const openNewApplicationModal = () => {
    coreApplicationStore.setApplicationActivity(true);
  };

  //-- applications fetching logic --//
  const applications = ref([]);
  const isAppFetchDone = ref(false);
  const fetchApplications = async (type = 'newest') => {
    try {
      isAppFetchDone.value = false;
      const {
        data: {
          data
        }
      } = await fetchApplicationsService({
        sort: type
      });
      data.applications = data.applications.map(app => {
        return {
          ...app,
          created_at: convertUTCToLocalTime(app.created_at),
          updated_at: convertUTCToLocalTime(app.updated_at)
        };
      });
      applications.value = data.applications;
    } catch (err) {
      console.error(err);
    } finally {
      isAppFetchDone.value = true;
    }
  };
  onMounted(() => {
    fetchApplications();
  });

  //-- application deletion logic --//
  const appToBeDeleted = reactive({
    id: '',
    name: ''
  });
  const isDeleteAppModalVisible = ref(false);
  /**
   * @param {import('./types/applicationList').IAppItem} item
   */
  const confirmDelete = item => {
    appToBeDeleted.id = item.id;
    appToBeDeleted.name = item.name;
    toggleActionDropdown(item.id);
    isDeleteAppModalVisible.value = true;
  };
  const deleteApp = async () => {
    try {
      await deleteApplicationService(appToBeDeleted.id);
      isDeleteAppModalVisible.value = false;
      appToBeDeleted.id = '';
      appToBeDeleted.name = '';
      buefy.toast.open('Application deleted!');
      await fetchApplications();
    } catch (err) {
      console.error(err);
    }
  };

  //-- application table logic --//
  /**
   * @param {import('./types/applicationList').IAppItem} eventPayload
   */
  const goToModules = eventPayload => {
    router.push('/application/' + eventPayload.id + '/modules');
  };
  /**
   * @param {string} rowId
   */
  const toggleActionDropdown = rowId => {
    // @VUE3: use functional template ref in vue 3 instead (check offical vue 3 docs for info), vue 2 composition api plugin doesn't support them
    vm.proxy.$refs[`appActionDropdown${rowId}`].toggle();
  };
  /**
   * @param {string} id 
   */
  const goToAppSettings = id => {
    router.push(`application/${id}/settings`);
  };
  // methods: {
  //     confirmDelete(tableRow) {
  //         const { id } = tableRow;
  //         this.toggleAppActionDropdown(id);
  //         this.$buefy.dialog.confirm({
  //             title: 'Deleting application',
  //             message: 'Are you sure you want to <b>delete</b> this application? This action cannot be undone.',
  //             confirmText: 'Delete application',
  //             type: 'is-danger',
  //             hasIcon: true,
  //             onConfirm: () => {
  //                 this.deleteApp(id);
  //                 this.$buefy.toast.open('Application deleted!');
  //             }
  //         });
  //     }
  // }
  return {
    openNewApplicationModal,
    applications,
    isAppFetchDone,
    appToBeDeleted,
    isDeleteAppModalVisible,
    confirmDelete,
    deleteApp,
    goToModules,
    toggleActionDropdown,
    goToAppSettings
  };
};
__sfc_main.components = Object.assign({
  NoResults,
  ConfirmDeleteModal
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
.header-btn {
  position: absolute;
  right: 25px;
  top: 15px;
}
.add-new-btn {
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  right: 0;
  top: -45px;
  .icon {
    margin-right: 1px !important;
  }
}
</style>

<style lang="scss">
@import '~@/style/variables.scss';
@import '~@/style/components.scss';
@import '~@/style/utilities.scss';
</style>
